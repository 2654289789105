import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";

function Blog() {
  return (
    <div>
      <nav>
        <Header />
      </nav>

      <div>
        <h1 className="text-center Gilroy-Bold text-[48px] my-6">
          Son Yazılarımız
        </h1>
      </div>

      <div className="container mx-auto">
        <div className="flex flex-col lg:flex-row gap-y-4 gap-x-4 px-4 lg:px-0">
          <a
            href="/yazilarimiz/1"
            className="lg:w-8/12 relative cursor-pointer"
          >
            <img
              className="w-full h-[500px] object-cover  rounded-2xl relative"
              src="/assets/img/blog/yemek_club_restaurant_secimi.webp"
              alt="yemek_club_restaurant_secimi.webp"
            />

            <div className="absolute bottom-2 w-full pl-4 flex flex-col gap-y-4">
              <h2 className=" text-white Gilroy-Bold text-[28px]">
                Yemek.club Üzerinden Restoran Seçerken Dikkat
                <br /> Edilmesi Gereken 5 İpucu
              </h2>
              <div className="flex gap-x-2">
                <div className="bg-primary p-3 rounded-full">
                  <img
                    alt="logo_white.webp"
                    src="/assets/img/logo_white.webp"
                    className="w-8"
                  />
                </div>

                <div>
                  <h6 className="text-white Gilroy-SemiBold text-[20px]">
                    Yemek.Club
                  </h6>
                  <p className="text-white/90 Gilroy-Medium"> 16.12.2024</p>
                </div>
              </div>
            </div>
          </a>

          <div className="lg:w-4/12 relative">
            <img
              className="w-full h-[500px] object-cover  rounded-2xl relative"
              src="/assets/img/blog/yemek_club_restaurant_secimi.webp"
              alt="yemek_club_restaurant_secimi.webp"
            />

            <div className="absolute bottom-2 w-full pl-4 flex flex-col gap-y-4">
              <h2 className=" text-white Gilroy-Bold text-[28px]">
                Kapıda Ödeme ile Güvenli Alışveriş
              </h2>
              <div className="flex gap-x-2">
                <div className="bg-primary p-3 rounded-full">
                  <img
                    alt="logo_white.webp"
                    src="/assets/img/logo_white.webp"
                    className="w-8"
                  />
                </div>

                <div>
                  <h6 className="text-white Gilroy-SemiBold text-[20px]">
                    Yemek.Club
                  </h6>
                  <p className="text-white/90 Gilroy-Medium"> 16.12.2024</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="grid gap-x-8 gap-y-12 grid-cols-1 px-4 lg:px-0 lg:grid-cols-4 mt-12">
          <div className="flex flex-col gap-y-2">
            <div>
              <img
                className="w-full h-auto object-cover  rounded-2xl "
                src="/assets/img/blog/yemek_club_restaurant_secimi.webp"
                alt="yemek_club_restaurant_secimi.webp"
              />
            </div>

            <div className="flex flex-col gap-y-3">
              <h2 className="Gilroy-Bold text-black text-[22px] px-1">
                Mobil Uygulama ile Kolayca Yemek Siparişi Verme Rehberi
              </h2>

              <div className="flex gap-x-2">
                <div className="bg-primary p-2 rounded-full">
                  <img
                    alt="logo_white.webp"
                    src="/assets/img/logo_white.webp"
                    className="w-8"
                  />
                </div>

                <div>
                  <h6 className="text-black Gilroy-SemiBold text-[16px]">
                    Yemek.Club
                  </h6>
                  <p className="text-black/90 Gilroy-Medium text-[14px]">
                    {" "}
                    16.12.2024
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col gap-y-2">
            <div>
              <img
                className="w-full h-auto object-cover  rounded-2xl "
                src="/assets/img/blog/yemek_club_restaurant_secimi.webp"
                alt="yemek_club_restaurant_secimi.webp"
              />
            </div>

            <div className="flex flex-col gap-y-3">
              <h2 className="Gilroy-Bold text-black text-[22px] px-1">
                Mobil Uygulama ile Kolayca Yemek Siparişi Verme Rehberi
              </h2>

              <div className="flex gap-x-2">
                <div className="bg-primary p-2 rounded-full">
                  <img
                    alt="logo_white.webp"
                    src="/assets/img/logo_white.webp"
                    className="w-8"
                  />
                </div>

                <div>
                  <h6 className="text-black Gilroy-SemiBold text-[16px]">
                    Yemek.Club
                  </h6>
                  <p className="text-black/90 Gilroy-Medium text-[14px]">
                    {" "}
                    16.12.2024
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col gap-y-2">
            <div>
              <img
                className="w-full h-auto object-cover  rounded-2xl "
                src="/assets/img/blog/yemek_club_restaurant_secimi.webp"
                alt="yemek_club_restaurant_secimi.webp"
              />
            </div>

            <div className="flex flex-col gap-y-3">
              <h2 className="Gilroy-Bold text-black text-[22px] px-1">
                Mobil Uygulama ile Kolayca Yemek Siparişi Verme Rehberi
              </h2>

              <div className="flex gap-x-2">
                <div className="bg-primary p-2 rounded-full">
                  <img
                    alt="logo_white.webp"
                    src="/assets/img/logo_white.webp"
                    className="w-8"
                  />
                </div>

                <div>
                  <h6 className="text-black Gilroy-SemiBold text-[16px]">
                    Yemek.Club
                  </h6>
                  <p className="text-black/90 Gilroy-Medium text-[14px]">
                    {" "}
                    16.12.2024
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col gap-y-2">
            <div>
              <img
                className="w-full h-auto object-cover  rounded-2xl "
                src="/assets/img/blog/yemek_club_restaurant_secimi.webp"
                alt="yemek_club_restaurant_secimi.webp"
              />
            </div>

            <div className="flex flex-col gap-y-3">
              <h2 className="Gilroy-Bold text-black text-[22px] px-1">
                Mobil Uygulama ile Kolayca Yemek Siparişi Verme Rehberi
              </h2>

              <div className="flex gap-x-2">
                <div className="bg-primary p-2 rounded-full">
                  <img
                    alt="logo_white.webp"
                    src="/assets/img/logo_white.webp"
                    className="w-8"
                  />
                </div>

                <div>
                  <h6 className="text-black Gilroy-SemiBold text-[16px]">
                    Yemek.Club
                  </h6>
                  <p className="text-black/90 Gilroy-Medium text-[14px]">
                    {" "}
                    16.12.2024
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col gap-y-2">
            <div>
              <img
                className="w-full h-auto object-cover  rounded-2xl "
                src="/assets/img/blog/yemek_club_restaurant_secimi.webp"
                alt="yemek_club_restaurant_secimi.webp"
              />
            </div>

            <div className="flex flex-col gap-y-3">
              <h2 className="Gilroy-Bold text-black text-[22px] px-1">
                Mobil Uygulama ile Kolayca Yemek Siparişi Verme Rehberi
              </h2>

              <div className="flex gap-x-2">
                <div className="bg-primary p-2 rounded-full">
                  <img
                    alt="logo_white.webp"
                    src="/assets/img/logo_white.webp"
                    className="w-8"
                  />
                </div>

                <div>
                  <h6 className="text-black Gilroy-SemiBold text-[16px]">
                    Yemek.Club
                  </h6>
                  <p className="text-black/90 Gilroy-Medium text-[14px]">
                    {" "}
                    16.12.2024
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col gap-y-2">
            <div>
              <img
                className="w-full h-auto object-cover  rounded-2xl "
                src="/assets/img/blog/yemek_club_restaurant_secimi.webp"
                alt="yemek_club_restaurant_secimi.webp"
              />
            </div>

            <div className="flex flex-col gap-y-3">
              <h2 className="Gilroy-Bold text-black text-[22px] px-1">
                Mobil Uygulama ile Kolayca Yemek Siparişi Verme Rehberi
              </h2>

              <div className="flex gap-x-2">
                <div className="bg-primary p-2 rounded-full">
                  <img
                    alt="logo_white.webp"
                    src="/assets/img/logo_white.webp"
                    className="w-8"
                  />
                </div>

                <div>
                  <h6 className="text-black Gilroy-SemiBold text-[16px]">
                    Yemek.Club
                  </h6>
                  <p className="text-black/90 Gilroy-Medium text-[14px]">
                    {" "}
                    16.12.2024
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col gap-y-2">
            <div>
              <img
                className="w-full h-auto object-cover  rounded-2xl "
                src="/assets/img/blog/yemek_club_restaurant_secimi.webp"
                alt="yemek_club_restaurant_secimi.webp"
              />
            </div>

            <div className="flex flex-col gap-y-3">
              <h2 className="Gilroy-Bold text-black text-[22px] px-1">
                Mobil Uygulama ile Kolayca Yemek Siparişi Verme Rehberi
              </h2>

              <div className="flex gap-x-2">
                <div className="bg-primary p-2 rounded-full">
                  <img
                    alt="logo_white.webp"
                    src="/assets/img/logo_white.webp"
                    className="w-8"
                  />
                </div>

                <div>
                  <h6 className="text-black Gilroy-SemiBold text-[16px]">
                    Yemek.Club
                  </h6>
                  <p className="text-black/90 Gilroy-Medium text-[14px]">
                    {" "}
                    16.12.2024
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col gap-y-2">
            <div>
              <img
                className="w-full h-auto object-cover  rounded-2xl "
                src="/assets/img/blog/yemek_club_restaurant_secimi.webp"
                alt="yemek_club_restaurant_secimi.webp"
              />
            </div>

            <div className="flex flex-col gap-y-3">
              <h2 className="Gilroy-Bold text-black text-[22px] px-1">
                Mobil Uygulama ile Kolayca Yemek Siparişi Verme Rehberi
              </h2>

              <div className="flex gap-x-2">
                <div className="bg-primary p-2 rounded-full">
                  <img
                    alt="logo_white.webp"
                    src="/assets/img/logo_white.webp"
                    className="w-8"
                  />
                </div>

                <div>
                  <h6 className="text-black Gilroy-SemiBold text-[16px]">
                    Yemek.Club
                  </h6>
                  <p className="text-black/90 Gilroy-Medium text-[14px]">
                    {" "}
                    16.12.2024
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col gap-y-2">
            <div>
              <img
                className="w-full h-auto object-cover  rounded-2xl "
                src="/assets/img/blog/yemek_club_restaurant_secimi.webp"
                alt="yemek_club_restaurant_secimi.webp"
              />
            </div>

            <div className="flex flex-col gap-y-3">
              <h2 className="Gilroy-Bold text-black text-[22px] px-1">
                Mobil Uygulama ile Kolayca Yemek Siparişi Verme Rehberi
              </h2>

              <div className="flex gap-x-2">
                <div className="bg-primary p-2 rounded-full">
                  <img
                    alt="logo_white.webp"
                    src="/assets/img/logo_white.webp"
                    className="w-8"
                  />
                </div>

                <div>
                  <h6 className="text-black Gilroy-SemiBold text-[16px]">
                    Yemek.Club
                  </h6>
                  <p className="text-black/90 Gilroy-Medium text-[14px]">
                    {" "}
                    16.12.2024
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <footer>
        <Footer />
      </footer>
    </div>
  );
}

export default Blog;
