import logo from "./logo.svg";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router";
import Home from "./screens/Home";
import KVKK from "./screens/KVKK";
import AydinlatmaMetni from "./screens/AydinlatmaMetni";
import UyeIsyeriBasvuruFormu from "./screens/UyeIsyeriBasvuruFormu";
import Contact from "./screens/Contact";
import Buisness from "./screens/Buisness";
import Blog from "./screens/Blog";
import BlogDetail from "./screens/BlogDetail";
import About from "./screens/About";
import { FaWhatsapp } from "react-icons/fa";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" index element={<Home />} />
        <Route path="/hakkimizda" element={<About />} />
        <Route path="/isletmeler" element={<Buisness />} />
        <Route path="/kvkk-ve-gizlilik-politikasi" element={<KVKK />} />
        <Route path="/aydinlatma-metni" element={<AydinlatmaMetni />} />
        <Route path="/iletisim" element={<Contact />} />
        <Route path="/yazilarimiz" element={<Blog />} />
        <Route path="/yazilarimiz/:id" element={<BlogDetail />} />
        <Route
          path="/uye-isyeri-onbasvuru-formu"
          element={<UyeIsyeriBasvuruFormu />}
        />
      </Routes>

      <a
        href="https://wa.me/+902429222022"
        target="_blank"
        class="fixed bottom-2 right-2"
      >
        <div className="bg-primary p-3 rounded-full">
          <FaWhatsapp className="text-white text-[36px]" />
        </div>
      </a>
    </BrowserRouter>
  );
}

export default App;
