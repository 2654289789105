import React from "react";
import Header from "../components/Header";
import WaveUP from "../components/WaveUP";
import { FaFilter, FaUser, FaUtensils } from "react-icons/fa";
import { FaBowlFood, FaCreditCard } from "react-icons/fa6";
import Footer from "../components/Footer";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
function Home() {
  return (
    <div>
      <nav>
        <Header />
      </nav>

      <div className="container mx-auto mt-8 lg:mt-24">
        <div className="flex flex-col lg:flex-row justify-between items-center ">
          <div className="flex lg:hidden flex-col relative w-[250px]  mt-12">
            <img src="/assets/img/hero_image.webp" alt="hero_image.webp" />

            <div className=" absolute  -top-10">
              <img
                src="/assets/img/hero_mockup.webp"
                alt="hero_image.webp"
                className=""
              />
            </div>
          </div>

          <div>
            <div className="lg:w-6/12 flex flex-col gap-y-6">
              <div>
                <h1 className="text-[48px] Gilroy-Bold text-center lg:text-left">
                  Yemek.Club
                </h1>
                <h2 className="text-[40px] Gilroy-Bold text-center lg:text-left">
                  Senin Uygulaman!
                </h2>
              </div>
              <div>
                <p className="text-center lg:text-left">
                  Lezzetli yemekleri saniyeler içinde bulman için buradayız.
                  Kullanıcı dostu arayüzümüzle, binlerce restoran ve menü
                  arasından dilediğin yemeği kolayca seç. Sadece birkaç tıklama
                  ile siparişini hızlı ve zahmetsiz bir şekilde tamamla. 
                </p>
              </div>
              <div className="flex items-center justify-between">
                <a href="https://play.google.com/store/apps/details?id=com.loc.yemekclub">
                  <img
                    src="/assets/img/googleplay.webp"
                    className="w-52"
                    alt="googleplay"
                  />
                </a>

                <a href="https://apps.apple.com/gr/app/yemek-club/id1672140958">
                  <img
                    src="/assets/img/appstore.webp"
                    className="w-52"
                    alt="applestore"
                  />
                </a>
              </div>
            </div>
          </div>

          <div className="hidden lg:flex lg:flex-col relative w-6/12">
            <img src="/assets/img/hero_image.webp" alt="hero_image.webp" />

            <div className=" absolute  -top-10">
              <img
                src="/assets/img/hero_mockup.webp"
                alt="hero_image.webp"
                className=""
              />
            </div>
          </div>
        </div>
      </div>

      <div className="container mx-auto mt-12 lg:mt-24">
        <div className="text-center ">
          <h2 className="Gilroy-SemiBold text-[42px]">Üye İşyerlerimiz</h2>
          <p className="hidden lg:flex lg:flex-col w-9/12 mx-auto Gilroy-Medium text-black/60">
            Yemek.Club'da her şey senin rahatlığın için düşünüldü. Geniş yemek
            yelpazesi, hızlı teslimat ve kişiselleştirilmiş önerilerle sipariş
            deneyimini bir öteye taşıyoruz. Kolay kullanım, hızlı çözümler ve
            kesintisiz hizmetle yemek keyfini çıkar. Senin Uygulaman!
          </p>
        </div>
        <div className="flex flex-col lg:flex-row justify-between items-center mt-4">
          <img
            src="/assets/img/partner_1.webp"
            className="w-36 opacity-45 hover:opacity-100"
            alt="partner_1"
          />
          <img
            src="/assets/img/partner_2.webp"
            className="w-36 opacity-45 hover:opacity-100"
            alt="partner_2"
          />
          <img
            src="/assets/img/partner_3.webp"
            className="w-36 opacity-45 hover:opacity-100"
            alt="partner_3"
          />
          <img
            src="/assets/img/partner_4.webp"
            className="w-36 opacity-45 hover:opacity-100"
            alt="partner_4"
          />
        </div>
      </div>

      <div className="mt-12 lg:mt-24 bg-primary py-12">
        <div>
          <h6 className="text-white text-center Gilroy-Bold text-[42px]">
            Özelliklerimiz
          </h6>
          <p className="text-center text-white Gilroy-Regular">
            Her şey senin için tasarlandı! Hızlı, güvenli ve kolay sipariş
            deneyimiyle yemek keyfi bir başka!
          </p>
        </div>

        <div className="mt-8 flex flex-col lg:flex-row items-center justify-center gap-x-12">
          <div className="flex flex-col gap-y-12">
            <div className="gap-y-3 flex flex-col text-white items-start lg:items-end text-left lg:text-right w-[300px]">
              <div>
                <FaUser className="text-[24px]" />
              </div>
              <div>
                <h6 className="text-left lg:text-right Gilroy-SemiBold text-[18px]">
                  Kullanıcı Dostu Tasarım
                </h6>
              </div>
              <div>
                <p className="Gilroy-Medium">
                  Kullanıcı Dostu Tasarım Her yaşta, herkesin rahatça
                  kullanabileceği ve anlayabileceği bir arayüz seni bekliyor!
                </p>
              </div>
            </div>

            <div className="gap-y-3 flex flex-col text-white lg:items-end lg:text-right w-[300px]">
              <div>
                <FaUtensils className="text-[24px]" />
              </div>
              <div>
                <h6 className="lg:text-right Gilroy-SemiBold text-[18px]">
                  Geniş Restoran Seçeneği
                </h6>
              </div>
              <div>
                <p className="Gilroy-Medium">
                  Canın ne çekiyorsa, istediğin mutfak türünü ve lezzeti kolayca
                  bulabileceğin geniş bir restoran yelpazesine sahipsin.
                </p>
              </div>
            </div>

            <div className="gap-y-3 flex flex-col text-white lg:items-end lg:text-right w-[300px]">
              <div>
                <FaFilter className="text-[24px]" />
              </div>
              <div>
                <h6 className="lg:text-right Gilroy-SemiBold text-[18px]">
                  Kolay Filtreleme
                </h6>
              </div>
              <div>
                <p className="Gilroy-Medium">
                  Yemeklerini konuma, yemek türüne, restoran puanına ve daha
                  fazlasına göre filtreleyerek tam istediğini bulabilirsin.
                </p>
              </div>
            </div>
          </div>
          <div className="hidden lg:flex lg:flex-col">
            <img
              src="/assets/img/features_mockup.webp"
              alt="features_mockup.webp"
              className="w-[300px]"
            />
          </div>
          <div className=" mt-8 lg:mt-0 flex flex-col gap-y-12">
            <div className="gap-y-3 flex flex-col text-white items-start text-left w-[300px]">
              <div>
                <FaCreditCard className="text-[24px]" />
              </div>
              <div>
                <h6 className="text-right Gilroy-SemiBold text-[18px]">
                  Güvenli Ödeme Seçenekleri
                </h6>
              </div>
              <div>
                <p className="Gilroy-Medium">
                  Nakit ya da kredi kartı fark etmez, her iki ödeme seçeneği de
                  güvenle kullanman için hazır.
                </p>
              </div>
            </div>

            <div className="gap-y-3 flex flex-col text-white items-start text-left w-[300px]">
              <div>
                <FaUtensils className="text-[24px]" />
              </div>
              <div>
                <h6 className="text-right Gilroy-SemiBold text-[18px]">
                  Kolay Sipariş Takibi
                </h6>
              </div>
              <div>
                <p className="Gilroy-Medium">
                  Siparişinin her aşamasını anlık olarak takip edebilir, kontrol
                  tamamen sende!
                </p>
              </div>
            </div>

            <div className="gap-y-3 flex flex-col text-white items-start text-left w-[300px]">
              <div>
                <FaUser className="text-[24px]" />
              </div>
              <div>
                <h6 className="text-right Gilroy-SemiBold text-[18px]">
                  Yorumlar ve Puanlama
                </h6>
              </div>
              <div>
                <p className="Gilroy-Medium">
                  Beğendiğin siparişleri kolayca tekrar verebilir, önceki
                  siparişlerini görüntüleyerek keyifli bir yemek deneyimini
                  yeniden yaşayabilirsin.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container mx-auto mt-12 lg:mt-24">
        <div className="text-center">
          <h2 className="Gilroy-SemiBold text-[42px]">Nasıl Çalışır?</h2>
          <p className="hidden lg:flex lg:flex-col w-9/12 mx-auto Gilroy-Medium text-black/60">
            Yemek.Club’u indir, üye ol ve favori restoranlarından sipariş ver.
            Kolayca seç, öde ve lezzetli yemeklerin kapına gelsin!
          </p>
        </div>

        <div className="mt-8 flex flex-col lg:flex-row justify-around gap-x-8 gap-y-8">
          <div className=" flex items-center flex-col lg:w-4/12 text-center">
            <div className="bg-primary rounded-full w-[50px] h-[50px] flex flex-col justify-center items-center">
              <p className="text-white text-[28px]">1</p>
            </div>

            <div>
              <h6 className="text-black Gilroy-Bold text-[21px] mt-2">
                Uygulamayı Yükle ve Lezzete Ulaş!
              </h6>

              <p className="Gilroy-Regular text-black/80 mt-2">
                Yemek.Club’u hemen telefonuna indir, lezzet dünyasına adım at!
                Google Play Store ya da App Store’dan kolayca indir, birkaç
                basit adımla kurulumunu tamamla. Artık en yakın restoranları
                keşfet ve favori yemeklerini anında sipariş et. Senin Uygulaman
                hazır, hemen başla!
              </p>
            </div>
          </div>

          <div className=" flex items-center flex-col lg:w-4/12 text-center">
            <div className="bg-primary rounded-full w-[50px] h-[50px] flex flex-col justify-center items-center">
              <p className="text-white text-[28px]">2</p>
            </div>

            <div>
              <h6 className="text-black Gilroy-Bold text-[21px] mt-2">
                Hesabını Oluştur ve Başla!
              </h6>

              <p className="Gilroy-Regular text-black/80 mt-2">
                Yemek.Club’a hemen üye ol, siparişlerini kolayca takip et,
                favori restoranlarını kaydet ve sipariş geçmişine anında ulaş.
                Kayıt işlemi sadece birkaç dakikanı alır! E-posta adresinle ya
                da sosyal medya hesabınla üye ol ve keyifli bir deneyime adım
                at. Senin Uygulaman seni bekliyor!
              </p>
            </div>
          </div>

          <div className=" flex items-center flex-col lg:w-4/12 text-center">
            <div className="bg-primary rounded-full w-[50px] h-[50px] flex flex-col justify-center items-center">
              <p className="text-white text-[28px]">3</p>
            </div>

            <div>
              <h6 className="text-black Gilroy-Bold text-[21px] mt-2">
                Sevdiğin Restoranlardan Sipariş Ver
              </h6>

              <p className="Gilroy-Regular text-black/80 mt-2">
                Favori restoranlarını keşfet, menülerini incele ve canının
                çektiği yemeği seç. Konumunu belirle, en yakın restoranlardan
                siparişini ver ve ödemeni kolayca yap. Yemeklerin güvenle kapına
                kadar gelsin! Yemek.Club ile sevdiğin lezzetlere ulaşmak işte bu
                kadar hızlı ve kolay!
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-24 w-full bg-primary py-16 text-center">
        <h2 className="Gilroy-Bold text-white text-[48px] lg:text-[62px] ">
          Aklınıza birşey mi takıldı ?
        </h2>
        <p className="Gilroy-Regular text-white/80 text-[18px] lg:text-[24px] mt-2 ">
          Aklınıza takılan sorular için iletişim formumuzu doldurarak destek
          ekibimiz tarafından bilgi alabilirsiniz.
        </p>

        <a href="/iletisim">
          <button className="Gilroy-Bold text-[18px] text-white border-white border-[2px]  py-3 px-12 rounded-full mt-7">
            Hemen Bilgi Al
          </button>
        </a>
      </div>

      {/*
      <div className="container mx-auto mt-12 lg:mt-24">
        <div className="text-center ">
          <h2 className="Gilroy-SemiBold text-[42px]">Sıkça Sorulan Sorular</h2>
        </div>

        <div className="mt-8">
       
        </div>
      </div>
      */}

      <footer>
        <Footer />
      </footer>
    </div>
  );
}

export default Home;
