import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import cityData from "../assets/cityData.json";
import axios from "axios";
import { FaColumns, FaLaptop, FaPercent } from "react-icons/fa";
import { FaBuildingColumns, FaShield } from "react-icons/fa6";
function UyeIsyeriBasvuruFormu() {
  const [city, setCity] = useState("none");
  const [district, setDistrict] = useState("none");
  const [neighbourhood, setNeighbourhood] = useState("none");
  const [districtList, setDistrictList] = useState([]);
  const [neighbourhoodList, setNeighbourhoodList] = useState([]);
  const cityList = [
    "Adana",
    "Adıyaman",
    "Afyonkarahisar",
    "Ağrı",
    "Amasya",
    "Ankara",
    "Antalya",
    "Artvin",
    "Aydın",
    "Balıkesir",
    "Bilecik",
    "Bingöl",
    "Bitlis",
    "Bolu",
    "Burdur",
    "Bursa",
    "Çanakkale",
    "Çankırı",
    "Çorum",
    "Denizli",
    "Diyarbakır",
    "Edirne",
    "Elazığ",
    "Erzincan",
    "Erzurum",
    "Eskişehir",
    "Gaziantep",
    "Giresun",
    "Gümüşhane",
    "Hakkari",
    "Hatay",
    "Isparta",
    "Mersin",
    "Istanbul",
    "Izmir",
    "Kars",
    "Kastamonu",
    "Kayseri",
    "Kırklareli",
    "Kırşehir",
    "Kocaeli",
    "Konya",
    "Kütahya",
    "Malatya",
    "Manisa",
    "Kahramanmaraş",
    "Mardin",
    "Muğla",
    "Muş",
    "Nevşehir",
    "Niğde",
    "Ordu",
    "Rize",
    "Sakarya",
    "Samsun",
    "Siirt",
    "Sinop",
    "Sivas",
    "Tekirdağ",
    "Tokat",
    "Trabzon",
    "Tunceli",
    "Şanlıurfa",
    "Uşak",
    "Van",
    "Yozgat",
    "Zonguldak",
    "Aksaray",
    "Bayburt",
    "Karaman",
    "Kırıkkale",
    "Batman",
    "Şırnak",
    "Bartın",
    "Ardahan",
    "Iğdır",
    "Yalova",
    "Karabük",
    "Kilis",
    "Osmaniye",
    "Düzce",
  ];

  const changeCity = async (e) => {
    setDistrict("none");
    setNeighbourhood("none");
    setDistrict([]);
    setNeighbourhoodList([]);
    setCity(e.target.value);
    if (e.target.value !== "none") {
      await axios
        .get(`https://api.yemek.club/user/api/getAddress/${e.target.value}`)
        .then((res) => {
          const tempArray = [];
          res.data.map((item) => {
            tempArray.push({ name: item.name });
          });
          setDistrictList(tempArray);
        });
    }
  };

  const changeDistrict = async (e) => {
    setNeighbourhood("none");
    setNeighbourhoodList([]);
    setDistrict(e.target.value);
    await axios
      .get(
        `https://api.yemek.club/user/api/getAddress/${city}?district=${e.target.value}`
      )
      .then((res) => {
        setNeighbourhoodList(res.data);
      });
  };

  return (
    <div>
      <nav>
        <Header />
      </nav>

      <div className="mt-12 lg:mt-24 text-center">
        <h1 className="text-black Gilroy-Heavy text-[32px]">
          Yemek.Club Üye İşyeri Ön Başvuru Formu
        </h1>
      </div>

      <div className="container mx-auto mt-4 lg:mt-24">
        <div className="flex flex-col lg:flex-row justify-between ">
          <div className="lg:w-5/12 flex flex-col gap-y-6 lg:gap-y-12 mt-4 px-4 lg:px-0">
            <div className="flex gap-x-3">
              <div className="bg-primary h-fit p-3 rounded-full">
                <FaPercent className="text-white text-[21px]" />
              </div>

              <div className="flex flex-col">
                <h6 className="text-[24px] Gilroy-Bold">Satış Başı Ücret</h6>
                <p className="Gilroy-Medium">
                  Yemek.Club Üye işyerleri 1.000 sipariş başına ₺1.000 öder.
                </p>
              </div>
            </div>

            <div className="flex gap-x-3">
              <div className="bg-primary h-fit p-3 rounded-full">
                <FaLaptop className="text-white text-[21px]" />
              </div>

              <div className="flex flex-col">
                <h6 className="text-[24px] Gilroy-Bold">
                  Detaylı Kontrol Paneli
                </h6>
                <p className="Gilroy-Medium">
                  Yemek.Club Üye işyerleri'ne özel tüm siparişlerini ve
                  istatistiklerini anlık takip edebilecekleri kontrol paneli.
                </p>
              </div>
            </div>

            <div className="flex gap-x-3">
              <div className="bg-primary h-fit p-3 rounded-full">
                <FaBuildingColumns className="text-white text-[21px]" />
              </div>

              <div className="flex flex-col">
                <h6 className="text-[24px] Gilroy-Bold">Ertesi Gün Ödeme</h6>
                <p className="Gilroy-Medium">
                  Yemek.Club Üye işyerleri'nin ürün satışından aldıkları
                  ödemeler ertesi gün hesaplarına iletilir.
                </p>
              </div>
            </div>

            <div className="flex gap-x-3">
              <div className="bg-primary h-fit p-3 rounded-full">
                <FaShield className="text-white text-[21px]" />
              </div>

              <div className="flex flex-col">
                <h6 className="text-[24px] Gilroy-Bold">Anlık Destek</h6>
                <p className="Gilroy-Medium">
                  Yemek.Club Üye işyerleri 7/24 teknik ekibimizden destek
                  alabilir.
                </p>
              </div>
            </div>
          </div>
          <div className="lg:w-7/12 mx-4 lg:mx-0 mt-12 lg:mt-0 border-[1px] border-[#F4F4F4] rounded-2xl py-12 px-6 flex flex-col gap-y-6">
            <div>
              <p className="Gilroy-Medium text-[17px] text-black/80">
                Aşağıdaki ön başvuru formunu doldurduktan sonra, yetkili
                ekibimiz bilgilerinizi titizlikle inceleyerek en kısa sürede
                sizlerle iletişime geçecektir.
              </p>
            </div>

            <div className="flex gap-x-4 items-center justify-between">
              <input
                className="border-[#F4F4F4] border-[1px] rounded-lg px-3 py-3 w-6/12 Gilroy-Medium"
                placeholder="Yetkili Adı"
                type="text"
              />
              <input
                className="border-[#F4F4F4] border-[1px] rounded-lg px-3 py-3 w-6/12 Gilroy-Medium"
                placeholder="Yetkili Soyadı"
                type="text"
              />
            </div>

            <div className="">
              <input
                className="border-[#F4F4F4] border-[1px] rounded-lg px-3 py-3 w-full Gilroy-Medium"
                placeholder="Yetkili E-Posta Adresi"
                type="email"
              />
            </div>

            <div className="">
              <input
                className="border-[#F4F4F4] border-[1px] rounded-lg px-3 py-3 w-full Gilroy-Medium"
                placeholder="Yetkili Telefon Numarası"
                type="number"
              />
            </div>

            <div className="">
              <select className="border-[#F4F4F4] border-[1px] rounded-lg px-3 py-3 w-full Gilroy-Medium">
                <option value={"none"}>İşletme Tipi</option>
                <option value={"none"}>
                  Şahıs İşletmesi (Vergi Levham Var)
                </option>
                <option value={"none"}>
                  Bireysel Başvuru (Vergi Levham Yok / Muafım)
                </option>
                <option value={"none"}>Limited Şirket</option>
                <option value={"none"}>Anonim Şirket</option>
              </select>
            </div>

            <div className="flex gap-x-4 items-center justify-between">
              <select
                value={city}
                onChange={(e) => changeCity(e)}
                className="border-[#F4F4F4] border-[1px] rounded-lg px-3 py-3 w-6/12 Gilroy-Medium"
              >
                <option value={"none"}>İl Seçiniz</option>
                {cityList.map((item, index) => (
                  <option value={item}>{item}</option>
                ))}
              </select>
              <select
                value={district}
                onChange={(e) => changeDistrict(e)}
                className="border-[#F4F4F4] border-[1px] rounded-lg px-3 py-3 w-6/12 Gilroy-Medium"
                disabled={districtList.length == 0}
              >
                <option value={"none"}>İlçe Seçiniz</option>
                {districtList.map((item, index) => (
                  <option value={item.name}>{item.name}</option>
                ))}
              </select>
            </div>

            <div className="flex gap-x-4 items-center justify-between">
              <select
                value={neighbourhood}
                onChange={(e) => setNeighbourhood(e.target.value)}
                className="border-[#F4F4F4] border-[1px] rounded-lg px-3 py-3 w-6/12 Gilroy-Medium"
                disabled={neighbourhoodList.length == 0}
              >
                <option value={"none"}>Mahalle Seçiniz</option>
                {neighbourhoodList.map((item, index) => (
                  <option value={item.name}>{item.name}</option>
                ))}
              </select>
              <input
                className="border-[#F4F4F4] border-[1px] rounded-lg px-3 py-3 w-6/12 Gilroy-Medium"
                placeholder="Tam Adres"
              />
            </div>

            <div className="flex gap-x-2 items-center ">
              <input type="checkbox" className="ml-1" />
              <p className="Gilroy-Medium text-black/80 text-[14px]">
                Girdiğim kişisel verilerimin{" "}
                <a href="/aydinlatma-metni" className="text-black">
                  <b>Aydınlatma Metni</b>
                </a>{" "}
                kapsamında işlenmesini kabul ediyorum.
              </p>
            </div>

            <div>
              <button className="bg-primary rounded-full w-full py-3">
                <p className="text-white Gilroy-Bold text-[18px]">Gönder</p>
              </button>
            </div>
          </div>
        </div>
      </div>

      <footer>
        <Footer />
      </footer>
    </div>
  );
}

export default UyeIsyeriBasvuruFormu;
