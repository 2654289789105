import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";

function BlogDetail() {
  return (
    <div>
      <nav>
        <Header />
      </nav>

      <div className="container mx-auto mt-12 px-4 lg:px-0">
        <div className="">
          <div className="w-full relative cursor-pointer">
            <img
              className="w-full h-[600px] object-cover object-center  rounded-2xl relative"
              src="/assets/img/blog/yemek_club_restaurant_secimi.webp"
              alt="yemek_club_restaurant_secimi.webp"
            />

            <div>
              <h1 className="Gilroy-Bold text-[36px] my-6">
                Yemek.club Üzerinden Restoran Seçerken Dikkat Edilmesi Gereken 5
                İpucu
              </h1>
            </div>
          </div>
        </div>

        <div className="flex flex-col gap-y-4">
          <p className="Gilroy-Medium text-black/70">
            Yemek siparişi verme deneyimi, geleneksel yöntemlere göre büyük bir
            kolaylık ve hız kazanmış durumda. Mobil uygulamalar sayesinde
            istediğiniz restorandan yemek siparişi vermek artık bir tık kadar
            yakın. Yemek.club olarak biz de kullanıcılarımıza daha kolay, hızlı
            ve güvenli bir alışveriş deneyimi sunmayı amaçlıyoruz. Ancak,
            sayısız restoran ve yemek alternatifiyle karşılaştığınızda, doğru
            seçimi yapmak bazen zorlayıcı olabilir. İşte Yemek.club üzerinden
            restoran seçerken dikkat etmeniz gereken 5 ipucu!
          </p>

          <ul className="flex flex-col gap-y-8">
            <li className="flex gap-y-1 flex-col">
              <h6 className="Gilroy-SemiBold text-[24px] text-black/90">
                1. Restoran Puanlarını Göz Önünde Bulundurun: Yüksek Puan,
                Yüksek Kalite
              </h6>
              <p className="Gilroy-Medium text-black/70">
                Yemek.club’un sunduğu en faydalı özelliklerden biri, her
                restoran ve yemeğin kullanıcılar tarafından puanlanmasıdır.
                Restoran puanları, sipariş verdiğiniz yemeğin kalitesi ve
                restoranın hizmeti hakkında size doğrudan bilgi verir.
                Kullanıcılar, hem yemeklerinin lezzetini hem de restoranın
                teslimat hızını, çalışanlarının müşteri hizmetleri kalitesini
                değerlendirerek bir puan verirler.
                <br /> Yemek.club mobil uygulamasında restoranları filtrelerken,
                puanlarına göre sıralama yapabilirsiniz. Örneğin,
                restoranlarınızı 4.5 yıldız ve üzeri olanlarla sınırlayabilir ve
                sadece yüksek puanlı mekanlardan sipariş verebilirsiniz. Bu,
                sizin için güvenilir bir seçim yapmanın en kolay yollarından
                biridir. Ayrıca, yüksek puanlı restoranlar genellikle müşteri
                memnuniyetine daha fazla özen gösterir ve hizmet kalitesini
                sürekli iyileştirme yoluna giderler.
                <br /> İpucu: Yemek.club üzerindeki filtreleme seçeneklerini
                kullanarak, restoranları en yüksek puana sahip olanlardan
                başlayarak sıralayabilir ve kendiniz için en iyi seçeneği
                kolayca bulabilirsiniz.
              </p>
            </li>

            <li className="flex gap-y-1 flex-col">
              <h6 className="Gilroy-SemiBold text-[24px] text-black/90">
                2. Menü Çeşitliliği ve Fiyat Aralığına Dikkat Edin
              </h6>
              <p className="Gilroy-Medium text-black/70">
                Bir restoran seçerken, menüsündeki çeşitliliği de göz önünde
                bulundurmak önemli bir adımdır. Yemek.club uygulamasında her
                restoranın menüsü detaylı bir şekilde yer alır ve fiyat
                aralıkları da açıkça belirtilir. Farklı bütçelere hitap eden
                seçenekler, her kullanıcı için büyük bir avantajdır.
                <br /> Eğer belirli bir türde yemek arıyorsanız, örneğin vegan,
                sağlıklı seçenekler ya da diyet yemekleri, bu tür yemekleri
                sunan restoranları filtreleyebilirsiniz. Ayrıca, özel menüler,
                set menüler veya kampanyalar gibi fırsatlar arıyorsanız,
                Yemek.club bu konuda da sizi yalnız bırakmaz. Fiyat aralığını
                belirleyerek, belirlediğiniz bütçeye uygun restoranlara
                odaklanabilir, gereksiz masraflardan kaçınabilirsiniz.
                <br /> İpucu: Yemek.club uygulamasında menü çeşitliliği ve fiyat
                aralığını filtreleyerek, hem damak tadınıza hitap eden hem de
                bütçenize uygun restoranları kolayca bulabilirsiniz.
              </p>
            </li>

            <li className="flex gap-y-1 flex-col">
              <h6 className="Gilroy-SemiBold text-[24px] text-black/90">
                3. Teslimat Süresi ve Hızlı Servis Seçeneklerine Odaklanın
              </h6>
              <p className="Gilroy-Medium text-black/70">
                Bir restoranın yemeklerinizi ne kadar hızlı ve sıcak bir şekilde
                getireceği, yemek siparişinizin genel deneyimi üzerinde büyük
                bir etkiye sahiptir. Yemek.club, teslimat süresi ve hız
                konusunda da kullanıcılara kolaylık sağlar.
                <br /> Restoranlar, genellikle yemeklerinin teslimat sürelerini
                belirtirler ve bu süreye göre sizin için uygun olanı seçmek,
                siparişinizin zamanında gelmesini sağlar. Yemek.club üzerinden
                restoranları ararken, teslimat süresi veya hızlı teslimat gibi
                seçeneklere dikkat etmek önemlidir. Eğer aceleniz varsa, yakın
                çevredeki hızlı teslimat yapan restoranları tercih
                edebilirsiniz. Ayrıca, kapıda ödeme veya online ödeme
                seçenekleri de teslimat süresini etkileyebilir, bu nedenle ödeme
                yöntemini seçerken de dikkatli olmalısınız.
                <br /> İpucu: Restoranların teslimat sürelerine göre sıralama
                yaparak, en hızlı teslimat yapan restoranlardan sipariş
                verebilir ve aç kaldığınızda beklemeden yemeğinizi
                alabilirsiniz.
              </p>
            </li>

            <li className="flex gap-y-1 flex-col">
              <h6 className="Gilroy-SemiBold text-[24px] text-black/90">
                4. Restoranın Yorumlarına ve Müşteri Geribildirimlerine Göz Atın
              </h6>
              <p className="Gilroy-Medium text-black/70">
                Bir restoran hakkında en iyi bilgiye sahip olabileceğiniz
                yerlerden biri, önceki müşteri yorumlarıdır. Yemek.club,
                kullanıcılarının deneyimlerini diğer kullanıcılara sunarak, bir
                restoran hakkında daha fazla bilgi edinmenizi sağlar. Yorumlar,
                yemeklerin lezzetinden servis hızına kadar her konuda size fikir
                verebilir. Ayrıca, yorumların çoğu genellikle restoranın
                gönderim kalitesi, ambiyansı ve müşteri hizmetleri gibi
                faktörler hakkında da ayrıntılı bilgi verir.
                <br /> Restoran seçerken yalnızca puanlara değil, yorumlara da
                göz atmak önemli olabilir. Özellikle yeni bir restorandan
                sipariş vermek istiyorsanız, yorumlar size bu mekanın ne kadar
                güvenilir olduğuna dair fikir verebilir. <br />
                İpucu: Yemek.club üzerinde her restoranın yorum kısmına bakarak,
                başkalarının deneyimlerinden faydalanabilir ve restoranın
                beklentilerinizi karşılayıp karşılamayacağına karar
                verebilirsiniz.
              </p>
            </li>

            <li className="flex gap-y-1 flex-col">
              <h6 className="Gilroy-SemiBold text-[24px] text-black/90">
                5. Filtreleme Seçeneklerini Kullanın ve Farklı Kategorilere Göre
                Arama Yapın
              </h6>
              <p className="Gilroy-Medium text-black/70">
                Yemek.club’un sunduğu filtreleme seçenekleri, restoran seçme
                sürecini oldukça kolaylaştırır. Örneğin, sadece vejetaryen veya
                glutensiz yemekler arıyorsanız, bu özellikleri filtreleyerek
                yalnızca istediğiniz türde restoranları görüntüleyebilirsiniz.
                Ayrıca, restoran türüne göre (fast food, fine dining, kahvaltı
                mekanları vb.) veya özel kampanyalar ve indirimli menüler gibi
                seçenekler ile restoranlarınızı daraltabilirsiniz.
                <br />
                Yemek.club’da restoranları sadece konumlarına göre değil,
                ihtiyacınıza göre de arama yapabilirsiniz. Örneğin, çocuklu
                aileler için uygun restoranlar veya romantik akşam yemekleri
                için önerilen mekanlar gibi kategorilere göz atabilirsiniz.{" "}
                <br />
                İpucu: Yemek.club'un geniş filtreleme seçeneklerinden
                yararlanarak, kişisel tercihleriniz ve ihtiyaçlarınıza en uygun
                restoranları kolayca bulabilir, zamandan tasarruf edebilirsiniz.
              </p>
            </li>

            <li className="flex gap-y-1 flex-col">
              <h6 className="Gilroy-SemiBold text-[24px] text-black/90">
                Sonuç
              </h6>
              <p className="Gilroy-Medium text-black/70">
                Yemek.club üzerinden restoran seçerken dikkate almanız gereken
                bu 5 ipucu, size yalnızca en kaliteli ve hızlı servis veren
                restoranları bulmakla kalmaz, aynı zamanda yemek siparişi
                deneyiminizi de çok daha keyifli hale getirir. Yüksek puanlı
                restoranları tercih etmek, müşteri yorumlarına göz atmak ve
                filtreleme seçeneklerini kullanarak ihtiyacınıza en uygun
                seçenekleri görmek, her siparişi daha iyi bir deneyime
                dönüştürür.
                <br /> Yemek.club olarak, her zaman en iyi restoranları, en
                kaliteli yemekleri ve en güvenli ödeme seçeneklerini sunmayı
                amaçlıyoruz. Uygulamamızı indirerek, restorandan yemeğinize
                kadar her aşamada size kolaylık sağlayacak özelliklerden
                faydalanabilirsiniz!
              </p>
            </li>
          </ul>
        </div>
      </div>

      <footer>
        <Footer />
      </footer>
    </div>
  );
}

export default BlogDetail;
