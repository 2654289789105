import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";

function KVKK() {
  return (
    <div>
      <nav>
        <Header />
      </nav>

      <div className="container mx-auto mt-12 px-4 lg:px-0">
        <div className="flex flex-col gap-y-8">
          <div>
            <h1 className="text-black Gilroy-Heavy text-[32px]">
              Kişisel Verilerin Korunması ve Gizlilik Politikası
            </h1>
          </div>

          <div>
            <h6 className="text-black Gilroy-Bold text-[24px]">
              İşbu Kişisel Verilerin Korunması ve Gizlilik Politikası;
            </h6>
            <p className="text-black Gilroy-Regular text-[16px]">
              ALTINDAĞ MAH. 100. YIL BLV. NO: 12 İÇ KAPI NO: 501 MURATPAŞA/
              ANTALYA adresinde mukim LOC DANIŞMANLIK A.Ş’nin (YemekClub) işbu
              Web Sitesi, Mobil Web, Mobil Uygulama siteleri aracılığıyla ve
              sair yollarla; ne tür kişisel veriler topladığını, Bu kişisel
              verilerin nasıl ve ne amaçla kullanıldığını, Şirket’in bu kişisel
              verileri kimlerle paylaşabileceğini, Şirket’in Web Sitesi, Mobil
              Web, Mobil Uygulama ve sair yollarla topladığı kişisel veriler ile
              ilgili olarak kullanıcılarının/üyelerinin/müşterilerinin
              (Kullanıcı) haklarının neler olduğunu ve bu hakları nasıl
              kullanabilecekleri konusunda bilgilendirmeyi içermektedir.
            </p>
          </div>

          <div>
            <h6 className="text-black Gilroy-Bold text-[24px]">
              Kişisel Verilerin Korunması Hakkında Bilgilendirme
            </h6>
            <p className="text-black Gilroy-Regular text-[16px]">
              6698 sayılı Kişisel Verilerin Korunması Kanunu (KVKK yada Kanun)
              uyarınca; aşağıda belirtilen işleme amacı ile bağlantılı ve
              sınırlı olacak şekilde Kullanıcıların Şirket ile paylaşmış olduğu
              işbu Kişisel Verilerin Korunması ve Gizlilik Politikasında
              belirtilen kişisel verilerinin, işlenmelerini gerektiren amaç
              çerçevesinde kaydedilecek, depolanacak, muhafaza edilecek,
              değiştirilecek, yeniden düzenlenecek, kanunen bu kişisel verileri
              talep etmeye yetkili olan kurumlar ile paylaşılacak, KVKK’nın
              öngördüğü hallerde ve koşullarda, yurt içi veya yurt dışında
              üçüncü kişilere aktarılacak, devredilebilecek, elde edilebilir
              hale getirilecek, sınıflandırılabilecek ve KVKK’da sayılan sair
              şekillerde işlenebilecek olduğunu bildiririz.
            </p>
          </div>

          <div>
            <h6 className="text-black Gilroy-Bold text-[24px]">
              İşlenen Kişisel Veriler, Kişisel Veri İşleme Amacı ve Dayanağı
            </h6>
            <p className="text-black Gilroy-Regular text-[16px]">
              Kanun ve hukuki prensipler gereği kişisel veriler; kişisel veri
              sahiplerinin açık rıza ve kabullerinin alınması ile
              işlenebilmektedir. Kanun’un 6. maddesinde ‘özel nitelikli kişisel
              veriler’ sınırlı bir şekilde sayılmıştır ve bunlar kişilerin ırkı,
              etnik kökeni, siyasi düşüncesi, felsefi inancı, dini, mezhebi veya
              diğer inançları, kılık ve kıyafeti, dernek, vakıf ya da sendika
              üyeliği, sağlığı, cinsel hayatı, ceza mahkûmiyeti ve güvenlik
              tedbirleriyle ilgili verileri ile biyometrik ve genetik verilerini
              içermektedir. Hazırlamış olduğumuz politika kapsamında belirtilen
              şekilde müşteri ve kullanıcıların özel nitelikli kişisel
              verilerini işlememekteyiz. Genel nitelikteki kişisel verilerinizi
              ise aşağıda belirttiğimiz hususlar sebebiyle işlemekteyiz.
              <br />
              · Kanunlarda açıkça belirtilen sebeplerden kaynaklı YemekClub’ın
              verilerinizi işlemesi.
              <br />
              · YemekClub’ın kişisel veri sahibinin mücbir sebeplerden ötürü can
              ve mal güvenliğinin tehlikeye girmesi halinde kişisel veri işleme
              faaliyetinin zorunlu olması ve kişisel veri sahibinin açık
              rızasının mümkün olmaması.
              <br />
              · Kişisel verilerinizin YemekClub tarafından işlenmesinin bir
              sözleşmenin kurulması veya ifasıyla doğrudan doğruya ilgili ve
              gerekli olması.
              <br />
              · Kişisel verilerinizin işlenmesinin YemekClub’ın hukuki
              yükümlülüğünü yerine getirebilmesi için zorunlu olması.
              <br />
              · Kişisel verilerinizin YemekClub tarafından işlenmesinin
              YemekClub’ın, sizlerin veya üçüncü kişilerin haklarının tesisi,
              kullanılması veya korunması için zorunlu olması.
              <br />
              · YemekClub’ın meşru menfaatleri için kişisel veri işleme
              faaliyetinde bulunulmasının zorunlu olması.
              <br />
              · Bilgi güvenliği süreçlerinin planlanması, denetimi ve icrası ile
              finans, muhasebe ve hukuk işlerinin takibi.
              <br />
              · Kurumsal iletişim faaliyetlerinin planlanması ve icrası ve
              kullanıcı talep ve/veya şikayetlerin takibi.
              <br />
              · Sözleşme süreçlerinin ve/veya hukuki taleplerin takibi.
              <br />
              · Sosyal medya hesaplarıyla bağlanılması durumunda Kullanıcının o
              kanallar aracılığıyla paylaşılmasına onay verdiği bilgiler gibi
              Kullanıcıyı doğrudan veya dolaylı olarak tanımlamaya yönelik
              kişisel veriler, Kullanıcıya daha iyi hizmet verebilmek, Kullanıcı
              ile sözleşme akdetmek, Kullanıcıya verilen hizmetin devamı ve
              iyileştirilmesi, satış, pazarlama ve ticari iletişim
              yapılabilmesi, buna bağlı operasyonel faaliyetlerin
              yürütülmesi/devam etmesi, istatistiki çalışmalar ve pazar
              araştırması yapılabilmesi gibi amaçlarla işlenebilmektedir.
              <br />
              Kişisel Veriler; Kullanıcılara daha iyi hizmet verebilmemiz adına
              Kullanıcıların, gerekli olduğu durumlarda açık rızalarına
              istinaden, yasal düzenlemeler uyarınca veri işleme amacı ve hukuki
              sebepler ile sınırlı olarak, bağlı ortaklıklarımıza ve/veya
              doğrudan/dolaylı yurtiçi/yurtdışı iştiraklerimize, Şirketin
              faaliyetlerini yürütmek üzere ilgili sözleşmeler kapsamında hizmet
              aldığı ya da işbirliği yaptığı yurt içi veya yurt dışındaki üçüncü
              kişilere aktarılabilecek, işbu Kişisel Verilerin Korunması ve
              Gizlilik Politikası ile belirlenen amaçlar ve kapsam dışında
              kullanılmamak kaydı ile KVKK’nın öngördüğü hallerde veri
              güvenliğine ilişkin tedbirler de alınarak KVKK’da sayılan
              şekillerde işlenebilecektir.
              <br />
              Kullanıcıya ait Kişisel Veriler ile gezinme ve trafik bilgileri;
              güvenlik ve yasalar karşısındaki yükümlülüğümüzü ifa etmek
              amacıyla (suçla mücadele, devlet ve kamu güvenliğinin tehdidi
              benzeri ve fakat bununla sınırlı olmamak üzere yasal veya idari
              olarak bildirim veya bilgi verme yükümlülüğümüzün mevcut olduğu
              durumlarda) yasal olarak bu bilgileri talep etmeye yetkili olan
              ilgili kurum ve kuruluşlar ile paylaşılabilecektir.
              <br />
            </p>
          </div>

          <div>
            <h6 className="text-black Gilroy-Bold text-[24px]">
              Kişisel Verilerle İlgili Haklar
            </h6>
            <p className="text-black Gilroy-Regular text-[16px]">
              Kullanıcı, işlenen Kişisel Verilerinin neler olduğunu ve işlenip
              işlenmediğini, işleme amacı doğrultusunda kullanılıp
              kullanılmadığını öğrenebilir, verileri işlenmişse bu hususta bilgi
              talep edebilir, yurt içi veya yurt dışında Kişisel Verilerin
              aktarıldığı üçüncü kişileri öğrenebilir, eksik veya yanlış işlenen
              Kişisel Veriler söz konusu ise veya işlenen Kişisel Verilerinde
              herhangi bir değişiklik olması durumunda bu verilerin
              düzeltilmesini, değiştirilmesini, güncellenmesini, KVKK’nın 7.
              maddesinde öngörülen şartlar çerçevesinde silinmesini/yok
              edilmesini veya anonim hale getirilmesini, bu işlemlerin (eksik
              veya yanlış işlenen Kişisel Verileri söz konusu ise, bu verilerin
              düzeltilmesini veya KVKK’nın 7. maddesinde öngörülen şartlar
              çerçevesinde silinmesini/yok edilmesini veya anonim hale
              getirilmesini) Kişisel Verilerin aktarıldığı üçüncü kişilere
              bildirilmesini talep edebilir; işlenen verilerin münhasıran
              otomatik sistemler ile analiz edilmesi nedeniyle aleyhine bir
              sonucun ortaya çıkmasına itiraz edebilir ve Kişisel Verilerin
              kanuna aykırı olarak işlenmesi sebebiyle zarara uğraması hâlinde
              zararın giderilmesini talep edebilir.
            </p>
          </div>

          <div>
            <h6 className="text-black Gilroy-Bold text-[24px]">
              Kişisel Veri Saklama Süresi
            </h6>
            <p className="text-black Gilroy-Regular text-[16px]">
              Örnek vermek gerekirse 6563 Sayılı Elektronik Ticaretin
              Düzenlenmesi Hakkında Kanun ile buna bağlı yönetmelik uyarınca
              Kişisel Verilerin pazarlama veya tanıtım amaçları için
              kullanılacağına ilişkin Kullanıcının onayının geri alındığı
              durumlarda, Kişisel Verilerin kayıtları bu tarihten itibaren 1 yıl
              saklanır. Ticari elektronik iletinin içeriği ve gönderiye ilişkin
              diğer her türlü kayıt ise gerektiğinde ilgili bakanlığa sunulmak
              üzere 3 yıl saklanır. 5651 Sayılı İnternet Ortamında Yapılan
              Yayınların Düzenlenmesi ve Bu Yayınlar Yoluyla İşlenen Suçlarla
              Mücadele Edilmesi Hakkında Kanun uyarınca ise işlediğimiz trafik
              verileri 2 yıl saklanır ve süre bittikten sonra anonim hale
              getirilir.
            </p>
          </div>

          <div>
            <h6 className="text-black Gilroy-Bold text-[24px]">
              Kişisel Verilerin Korunmasına İlişkin Önlemler
            </h6>
            <p className="text-black Gilroy-Regular text-[16px]">
              Kişisel Verileri gizli tutmayı, gizliliğin sağlanması ve güvenliği
              için gerekli teknik ve idari her türlü tedbiri almayı ve gerekli
              özeni göstermeyi taahhüt etmekteyiz. Gerekli bilgi güvenliği
              önlemlerini almamıza karşın, Şirkete ait Web Sitesine, Mobil Web’e
              ve sisteme yapılan saldırılar sonucunda Kişisel Verilerin zarar
              görmesi veya üçüncü kişilerin eline geçmesi durumunda, bu durumu
              derhal sizlere ve Kişisel Verileri Koruma Kurulu’na bildirir ve
              gerekli önlemleri alırız.
            </p>
          </div>

          <div>
            <h6 className="text-black Gilroy-Bold text-[24px]">
              Kişisel Verilerin Korunması ve Gizlilik Politikasında Yapılacak
              Değişiklikler
            </h6>
            <p className="text-black Gilroy-Regular text-[16px]">
              İşbu Kişisel Verilerin Korunması ve Gizlilik Politikasında zaman
              zaman değişiklik yapılabilir. Bu değişiklikler, değiştirilmiş yeni
              Kişisel Verilerin Korunması ve Gizlilik Politikasının Web
              Sitesinde/Mobil Web’de yayınlanmasıyla birlikte derhal geçerlilik
              kazanır. Bu sebeple Kullanıcının işbu metni periyodik olarak
              gözden geçirmesini öneririz. Kullanıcı, varsa Kişisel Bilgileri de
              içeren üyelik bilgilerini ve iletişim tercihlerini her zaman Web
              Sitesinde yer alan iletişim bilgilerinden Şirkete ulaşarak da
              iletebilir. Talepler en kısa sürede değerlendirilerek uygulamaya
              alınacaktır.
            </p>
          </div>

          <div>
            <h6 className="text-black Gilroy-Bold text-[24px]">
              İletişim İzni
            </h6>
            <p className="text-black Gilroy-Regular text-[16px]">
              Kullanıcının Şirket ile paylaşmış olduğu Kişisel Veriler;
              satış-pazarlama amacıyla diğer şirketlerden topladığımız bilgiler
              ile birleştirerek hizmetlerimizin içerik ve tanıtımlarımızın
              geliştirilmesi ve kişileştirilmesi veya yeni hizmetler sunabilmek
              amacıyla ve her türlü iletişim mesajlarının gönderilmesi amacıyla
              kullanılabilir. Bu veriler, aynı zamanda sadece Kullanıcıya
              sağlanacak hizmetlerin kusursuz sunulabilmesi, olası
              gönderilerinizin sağlıklı şekilde teslim edilmesi, telefon, sms
              ve/veya e-posta yoluyla bildirimlerimizin zamanında
              ulaştırılabilmesi amacıyla, sözleşme ilişkisi içinde olduğumuz,
              veri güvenliği konusundaki hassasiyetimizi paylaşan, ilgili
              mevzuat hükümlerine riayet eden 3. kişilerle, yalnızca ihtiyaç
              durumunda ve gerekli ölçüde paylaşılacaktır. Kullanıcı, pazarlama
              amaçlı bu tanıtımları almamak veya söz konusu reklam,
              kişiselleştirme, yeniden hedefleme uygulamalarında yer almak
              istemediği takdirde, taleplerini her zaman Web Sitesinde yer alan
              iletişim bilgilerinden Şirkete ulaşarak da iletebilir.
            </p>
          </div>
        </div>
      </div>

      <footer>
        <Footer />
      </footer>
    </div>
  );
}

export default KVKK;
