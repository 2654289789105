import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import {
  FaBullseye,
  FaCertificate,
  FaCreditCard,
  FaEye,
  FaFaceSmile,
  FaUtensils,
} from "react-icons/fa6";
import ContactBlock from "../components/ContactBlock";

function About() {
  return (
    <div>
      <nav>
        <Header />
      </nav>

      <div>
        <h1 className="text-center Gilroy-Bold text-[48px] my-6">Hakkımızda</h1>
      </div>

      <div className="container mx-auto mt-4 lg:mt-12">
        <div className="flex flex-col lg:flex-row gap-x-4">
          <div className="lg:w-6/12">
            <h2 className="text-[28px] Gilroy-Bold mb-2 text-center lg:text-left">
              Hikayemiz
            </h2>

            <div className="flex flex-col gap-y-2">
              <p className="Gilroy-Medium text-black/70">
                Bizim hikayemiz, Türk yemek kültürünün köklü geçmişinden ve
                eşsiz zenginliğinden doğuyor. Yüzyıllardır farklı coğrafyalarda
                şekillenen bu kültür, hem geleneksel lezzetleri modern
                yorumlarla buluşturuyor hem de sofralarımıza ilham veriyor. İşte
                bu yüzden, bu eşsiz mirası ve dünya mutfaklarının büyüleyici
                tatlarını sana en uygun fiyatlarla ulaştırmayı kendimize görev
                edindik.
              </p>
              <p className="Gilroy-Medium text-black/70">
                Yola çıkarken hedefimiz sadece lezzetli yemekleri sunmak değil,
                aynı zamanda bu lezzetleri en uygun fiyatlarla ulaştırarak sana
                değerli bir deneyim yaşatmaktı. Restoran iş ortaklarımızla
                kurduğumuz güçlü bağlar sayesinde hem sana en kaliteli hizmeti
                sunuyoruz hem de iş ortaklarımız için kazançlı bir sistem
                sağlıyoruz. Böylece herkesin kazandığı bir modeli birlikte inşa
                ettik!
              </p>

              <p className="Gilroy-Medium text-black/70">
                Bizim için önemli olan ne istediğini anlamak ve sana tam da buna
                uygun bir hizmet sunmak. Çünkü biliyoruz, her tercihinin
                arkasında bir hikaye var. Kullanıcı dostu arayüzümüzle binlerce
                seçenek arasından aradığın lezzeti kolayca bulabilir, hızlıca
                sipariş verebilirsin. Restoranlar, menüler ve fiyatlar arasında
                kaybolmana gerek yok! Yemek.Club ile her şey sana göre
                düşünüldü. Senin uygulaman, senin lezzet yolculuğun!
              </p>
            </div>
          </div>

          <div className="hidden lg:flex lg:flex-col w-6/12 ">
            <img
              src="/assets/img/hakkimizda.webp"
              alt="hakkimizda.webp"
              className="w-full h-full rounded-2xl"
            />
          </div>
        </div>
      </div>

      <div className="container mx-auto mt-12 lg:mt-24 ">
        <h2 className="text-center text-[28px] lg:text-[36px] Gilroy-SemiBold">
          Neden Yemek.Club ?
        </h2>

        <div className="grid grid-cols-1 lg:grid-cols-3 gap-y-4 mt-4 gap-x-4">
          <div className="p-4 pb-8 rounded-lg border-[1px] border-black/5 flex flex-col items-center gap-y-2">
            <div className="mt-4 bg-black/90 p-2 rounded-full w-fit">
              <FaUtensils className="text-[32px] text-white" />
            </div>

            <div>
              <h6 className="text-[20px] Gilroy-SemiBold">
                Restoran İş Ortaklarımızın Memnuniyeti
              </h6>
            </div>

            <div>
              <p className="text-[14px] Gilroy-Medium text-black/70">
                İşletmelerin başarısı bizim önceliğimiz! Her restoran iş
                ortağımız için en uygun iş planlarını hazırlayarak, onların
                kazancını artırmayı ve müşterilerine en iyi hizmeti sunmalarını
                sağlıyoruz. Bu sayede restoranlar, müşterilerine daha hızlı
                ulaşırken, onlara daha uygun fiyatlarla hizmet verebiliyor.
              </p>
            </div>
          </div>

          <div className="p-4 pb-8 rounded-lg border-[1px] border-black/5 flex flex-col items-center gap-y-2">
            <div className="mt-4 bg-black/90 p-2 rounded-full w-fit">
              <FaCreditCard className="text-[32px] text-white" />
            </div>

            <div>
              <h6 className="text-[20px] Gilroy-SemiBold">Ödeme Seçenekleri</h6>
            </div>

            <div>
              <p className="text-[14px] Gilroy-Medium text-black/70">
                Nasıl ödemek istersen, karar senin! Kapıda ödeme ya da online
                ödeme seçenekleriyle siparişini dilediğin gibi tamamla ve lezzet
                keyfine hemen başla. Yemek.Club ile anında uygun fiyat ve pratik
                çözümler parmaklarının ucunda!
              </p>
            </div>
          </div>

          <div className="p-4 pb-8 rounded-lg border-[1px] border-black/5 flex flex-col items-center gap-y-2">
            <div className="mt-4 bg-black/90 p-2 rounded-full w-fit">
              <FaFaceSmile className="text-[32px] text-white" />
            </div>

            <div>
              <h6 className="text-[20px] Gilroy-SemiBold">
                Müşteri Memnuniyeti
              </h6>
            </div>

            <div>
              <p className="text-[14px] Gilroy-Medium text-black/70">
                Senin mutluluğun, bizim önceliğimiz! İş ortaklarımızla düzenli
                denetimler yaparak, geri bildirimlerinizi dikkate alıyor ve
                önerilerinizle daha iyi çözümler üretiyoruz. Sana en iyi hizmeti
                sunmak için her zaman buradayız!
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-12 w-full bg-primary py-16 text-center">
        <h2 className="Gilroy-Bold text-white text-[48px] lg:text-[62px] ">
          Aklınıza birşey mi takıldı ?
        </h2>
        <p className="Gilroy-Regular text-white/80 text-[18px] lg:text-[24px] mt-2 ">
          Aklınıza takılan sorular için iletişim formumuzu doldurarak destek
          ekibimiz tarafından bilgi alabilirsiniz.
        </p>

        <a href="/iletisim">
          <button className="Gilroy-Bold text-[18px] text-white border-white border-[2px]  py-3 px-12 rounded-full mt-7">
            Hemen Bilgi Al
          </button>
        </a>
      </div>

      <div className="container mx-auto mt-8 px-4 lg:px-0">
        <h2 className="text-center text-[28px] lg:text-[36px] poppins-semibold">
          Misyonumuz & Vizyonumuz
        </h2>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-y-4 mt-8 gap-x-4">
          <div className="p-4 pb-8 rounded-lg border-[1px] border-black/5 flex flex-col items-center gap-y-2">
            <div className="mt-4 bg-black/90 p-2 rounded-full w-fit">
              <FaBullseye className="text-[32px] text-white" />
            </div>

            <div>
              <h6 className="text-[20px] Giroy-SemiBold">Misyonumuz</h6>
            </div>

            <div>
              <p className="text-[14px] Gilroy-Medium text-black/70">
                Yemek.Club olarak, sevdiğin restoranlardan dilediğin yemeklere
                hızlı, güvenli ve kolayca ulaşmanı sağlıyoruz. Amacımız, hem
                restoranlara hem de sana dijital dünyada eşsiz bir kolaylık
                sunarak yemek siparişi deneyimini en iyi hale getirmek. Her
                zaman memnuniyetini ön planda tutuyor, seçimlerinde kalite ve
                erişilebilirliği artırıyoruz. Yemek.Club - Senin Uygulaman!
              </p>
            </div>
          </div>
          <div className="p-4 pb-8 rounded-lg border-[1px] border-black/5 flex flex-col items-center gap-y-2">
            <div className="mt-4 bg-black/90 p-2 rounded-full w-fit">
              <FaEye className="text-[32px] text-white" />
            </div>

            <div>
              <h6 className="text-[20px] Giroy-SemiBold">Vizyonumuz</h6>
            </div>

            <div>
              <p className="text-[14px] Gilroy-Medium text-black/70">
                Yemek.Club olarak, yemek siparişi ve teslimatında sektörün
                öncüsü olmayı hedefliyoruz. Kullanıcı dostu arayüzümüz,
                yenilikçi ödeme seçeneklerimiz ve beklentilerin ötesine geçen
                hizmetlerimizle restoranlar ve müşteriler arasında güvenilir bir
                köprü kuruyoruz. Gelecekte, tüm yemek severlerin vazgeçilmez
                tercihi olarak dünyanın her yerinde yerel lezzetlere kolay
                erişim sağlamayı amaçlıyoruz. Yemek.Club - Senin Uygulaman!
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-24">
        <ContactBlock />
      </div>

      <footer>
        <Footer />
      </footer>
    </div>
  );
}

export default About;
