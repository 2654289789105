import React from "react";
import { FaFacebook, FaInstagram, FaTiktok, FaTwitter } from "react-icons/fa6";
function Footer() {
  return (
    <footer className="mt-28 ">
      <div className="bg-black/90 w-full py-16">
        <div className="container mx-auto">
          <div className="grid grid-cols-1 lg:grid-cols-4 gap-6 px-4">
            <div className="flex flex-col gap-y-4">
              <a
                href="https://yemek.club"
                className="text-white text-[34px] Gilroy-ExtraBold"
              >
                Yemek.Club
              </a>
              <p className="text-white/90 Gilroy-Regular text-[14px]">
                Yemek.club, en yakın restoranlardan hızlı ve güvenli bir şekilde
                yemek siparişi vermenizi sağlayan pratik bir mobil uygulamadır.
              </p>

              <div className="grid grid-cols-4">
                <FaTiktok className="text-[21px] text-white" />
                <FaTwitter className="text-[21px] text-white" />
                <FaInstagram className="text-[21px] text-white" />
                <FaFacebook className="text-[21px] text-white" />
              </div>
            </div>
            <div className="flex flex-col gap-x-4 lg:mx-auto">
              <h6 className="Gilroy-SemiBold text-white text-[21px] mb-4">
                Hızlı Bağlantılar
              </h6>
              <ul className="text-white Gilroy-Regular flex flex-col gap-y-2">
                <li>
                  <a href="/">Ana Sayfa</a>
                </li>
                <li>
                  <a href="/hakkimizda">Hakkımızda</a>
                </li>
                <li>
                  <a href="/isletmeler">İşletmeler için</a>
                </li>
                <li>
                  <a href="/iletisim">İletişim</a>
                </li>
                <li>
                  <a href="/yazilarimiz">Blog</a>
                </li>
              </ul>
            </div>
            <div className="flex flex-col gap-x-4 lg:mx-auto">
              <h6 className="Gilroy-SemiBold text-white text-[21px] mb-4">
                Yemek.Club'ı Keşfedin
              </h6>
              <ul className="text-white Gilroy-Regular flex flex-col gap-y-2">
                <li>
                  <a href="/uye-isyeri-onbasvuru-formu">Üye işyerimiz olun</a>
                </li>
                <li>
                  <a href="/kvkk-ve-gizlilik-politikasi">
                    KVKK ve Gizlilik Politikası
                  </a>
                </li>
                <li>
                  <a href="/aydinlatma-metni">Aydınlatma Metni</a>
                </li>
                <li>
                  <a href="/">Sıkça Sorulan Sorular</a>
                </li>
              </ul>
            </div>
            <div className="flex flex-col gap-x-4 lg:mx-auto">
              <ul className="text-white poppins-regular flex flex-row gap-x-4 lg:gap-x-0 lg:flex-col gap-y-4 lg:items-center">
                <li>
                  <a href="https://apps.apple.com/gr/app/yemek-club/id1672140958">
                    <img
                      src="/assets/img/googleplay.webp"
                      alt="googleplay"
                      className="w-36"
                    />
                  </a>
                </li>
                <li>
                  <a
                    href="https://play.google.com/store/apps/details?id=com.loc.yemekclub"
                    target="_blank"
                  >
                    <img
                      src="/assets/img/appstore.webp"
                      alt="appstore"
                      className="w-36"
                    />
                  </a>
                </li>

                <li>
                  <img
                    src="/assets/img/etbis_qr.jpg"
                    alt="appstore"
                    className="w-24"
                  />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full bg-black/80  flex flex-col items-center text-white py-2">
        <p>
          <a href="https://yemek.club">Yemek.Club</a> © Tüm Hakları Saklıdır.
          2024
        </p>
      </div>
    </footer>
  );
}

export default Footer;
