import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";

function AydinlatmaMetni() {
  return (
    <div>
      <nav>
        <Header />
      </nav>

      <div className="container mx-auto mt-12 px-4 lg:px-0">
        <div className="flex flex-col gap-y-8">
          <div>
            <h1 className="text-black Gilroy-Heavy text-[32px]">
              Aydınlatma Metni
            </h1>
          </div>

          <div>
            <h6 className="text-black Gilroy-Bold text-[24px]">
              KİŞİSEL VERİLERİN İŞLENMESİNE İLİŞKİNAYDINLATMA METNİ
            </h6>
            <p className="text-black Gilroy-Regular text-[16px]">
              Yemek Club olarak, siz müşterilerimize ait kişisel verilerin, 6698
              sayılı Kişisel Verilerin Korunması Kanunu (“KVKK”), ikincil
              düzenlemeleri ve Kişisel Verileri Koruma Kurulu Kararlarına uygun
              olarak işlenmesi ve korunması için azami hassasiyeti
              göstermekteyiz. Bu aydınlatma metni (“Aydınlatma Metni”), “Yemek
              Club” mobil uygulaması (“Uygulama”) aracılığıyla, kişisel
              verilerinizi veri sorumlusu sıfatıyla nasıl işlediğimize dair
              sizleri bilgilendirmek amacıyla hazırlanmıştır.
              <br />
              <br />
              Kişisel verilerinizin korunmasına yönelik aldığımız tedbirler ve
              kişisel verileri işlerken uyum sağladığımız kurallar hakkında
              detaylı bilgi için ayrıca Kişisel Verilerin İşlenmesi ve Korunması
              Politikamıza göz atmanızı öneririz.
              <br />
              <br />
              Kullanıcı dostu arayüzü ile yemek siparişi vermenizi çok
              kolaylaştırdık. Dilediğiniz yemeği binlerce seçenek arasından
              saniyeler içinde bulabilirsiniz. Yemeğinizi bulduktan sonra da
              gerisini bize bırakın! Kapıda veya online ödedikten sonra hızlı
              servis sistemimizle en sevdiğiniz restoran anında kapınızda
              belirebilir!
            </p>
          </div>

          <div>
            <h6 className="text-black Gilroy-Bold text-[24px]">
              Yemek Club Hangi Kişisel Verilerinizi İşler? Kişisel Verilerinizi
              Hangi Yöntemlerle, Amaçlarla ve Hangi Hukuki Sebeplere Dayanarak
              İşler?
            </h6>
            <p className="text-black Gilroy-Regular text-[16px]">
              Uygulama üzerinden, çağrı merkezi aracılığıyla telefon üzerinden
              ve bizimle e-posta aracılığıyla iletişim kurmanız halinde e-posta
              üzerinden tamamen otomatik ve kısmen otomatik yöntemler ile elde
              ettiğimiz kişisel verilerinizi, KVKK'nın 5. maddesinde belirtilen
              hukuki sebeplere dayalı olarak işliyoruz. Kişisel verilerinizi
              işleme amaçlarımız ve dayandığımız hukuki sebeplere aşağıda yer
              verdik: Size sunduğumuz hizmet kapsamında aşağıda belirtilen
              kişisel verileri altında belirtilen amaçlar kapsamında işliyoruz:
            </p>
            <br />
            <p className="text-black Gilroy-Regular text-[16px]">
              <b className="Gilroy-Medium">1. Kimlik Bilgileri:</b> Ad-soyad,
            </p>
          </div>

          <div>
            <h6 className="text-black Gilroy-Bold text-[24px]">
              İşleme Amaçları;
            </h6>
            <p className="text-black Gilroy-Regular text-[16px]">
              · Hukuki talep ve başvurularınızın incelenmesi, değerlendirilmesi
              ve sonuçlandırılması; olası hukuki uyuşmazlıkların giderilmesi ve
              bu amaçla yetkili kişi, kurum ve kuruluşlarla bilgi paylaşımında
              bulunulması.
              <br />
              · Uygulama üzerinden verdiğiniz siparişlere ilişkin sözleşme ve
              satış süreçlerinin yürütülmesi,
              <br />
              · Ödemelere ilişkin finans ve muhasebe işlerinin yürütülmesi,
              siparişiniz sonucunda fatura düzenlenmesi.
              <br />
              · Faaliyetlerimizin mevzuata uygun olarak yürütülmesi ve hukuki
              yükümlülüklerimiz kapsamında saklanması zorunlu olan verilerin
              muhafazası.
              <br />· Hizmetlerimizden yararlanabilmeniz için bilgilerinizin
              doğrulanması, Uygulamada üyelik kaydınızın oluşturulması ve
              hesabınıza giriş imkânı sağlanması.
            </p>
          </div>
        </div>
      </div>

      <footer>
        <Footer />
      </footer>
    </div>
  );
}

export default AydinlatmaMetni;
