import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import {
  FaBox,
  FaCertificate,
  FaChartBar,
  FaChartLine,
  FaComment,
  FaCreditCard,
  FaFaceSmile,
  FaFilter,
  FaFilterCircleDollar,
  FaHeadset,
  FaUser,
  FaUtensils,
  FaWallet,
} from "react-icons/fa6";

function Buisness() {
  return (
    <div>
      <nav>
        <Header />
      </nav>

      <div>
        <div className="container mx-auto mt-8 lg:mt-24">
          <div className="flex flex-col lg:flex-row justify-between items-center ">
            <div className="relative w-[300px] flex flex-col lg:hidden">
              <img src="/assets/img/hero_image.png" className="w-[300px]" />

              <div className=" absolute -left-20">
                <img
                  src="/assets/img/buisness_hero_mockup.webp"
                  className="w-[600px]"
                />
              </div>
            </div>

            <div>
              <div className="lg:w-6/12 flex flex-col gap-y-6">
                <div>
                  <h1 className="text-[48px] Gilroy-Bold text-center lg:text-left">
                    İşletmeler
                  </h1>
                  <h2 className="text-[40px] Gilroy-Bold text-center lg:text-left">
                    için en doğru adres!
                  </h2>
                </div>
                <div>
                  <p className="text-center lg:text-left">
                    Yemek.Club, restoranını büyütmek için yanında. Kolay
                    yönetim, geniş müşteri kitlesi ve etkili çözümlerle işini
                    bir adım öteye taşı. Katıl ve farkı hemen yaşa!
                  </p>
                </div>
                <a href="/uye-isyeri-onbasvuru-formu" className="p-2 lg:p-0">
                  <button className="w-full py-3 text-center bg-primary rounded-full text-white Gilroy-Bold text-[18px]">
                    Üye İşyerimiz Olun
                  </button>
                </a>
              </div>
            </div>

            <div className="relative w-6/12 hidden lg:flex lg:flex-col">
              <img src="/assets/img/hero_image.png" />

              <div className=" absolute -left-60  -top-10">
                <img
                  src="/assets/img/buisness_hero_mockup.webp"
                  className="min-w-[800px]"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container mx-auto mt-12 lg:mt-24 ">
        <h2 className="text-center text-[28px] lg:text-[36px] Gilroy-SemiBold">
          İşletmeler için Neden Yemek.Club ?
        </h2>

        <div className="grid grid-cols-1 lg:grid-cols-3 gap-y-4 mt-4 gap-x-4">
          <div className="p-4 pb-8 rounded-lg border-[1px] border-black/5 flex flex-col items-center gap-y-2">
            <div className="mt-4 bg-primary p-2 rounded-full w-fit">
              <FaChartLine className="text-[32px] text-white" />
            </div>

            <div>
              <h6 className="text-[20px] Gilroy-SemiBold">
                Detaylı Yönetim Paneli
              </h6>
            </div>

            <div>
              <p className="text-[14px] Gilroy-Medium text-black/70">
                Yemek.Club Yönetim Paneli ile restoranınızı kolayca yönetin!
                Ürünlerinizi hızlıca ekleyip düzenleyebilir, ihtiyaç
                duyduğunuzda silebilirsiniz. Siparişlerinizi anlık olarak takip
                edin, durumlarını kolayca güncelleyin. Kapıda ödeme ve online
                ödeme seçeneklerinizi dilediğiniz gibi izleyin ve işletmenizin
                tüm verilerini tek bir panelden kontrol edin. Kolay, hızlı,
                pratik! Senin Uygulaman!
              </p>
            </div>
          </div>

          <div className="p-4 pb-8 rounded-lg border-[1px] border-black/5 flex flex-col items-center gap-y-2">
            <div className="mt-4 bg-primary p-2 rounded-full w-fit">
              <FaWallet className="text-[32px] text-white" />
            </div>

            <div>
              <h6 className="text-[20px] Gilroy-SemiBold">
                Ertesi Gün Ödeme ve Düşük Komisyonlar
              </h6>
            </div>

            <div>
              <p className="text-[14px] Gilroy-Medium text-black/70">
                Avantaj seni bekliyor! Online ödemelerin, ertesi gün sistemde
                kayıtlı IBAN adresine aktarılır. Üstelik Yemek.Club,
                siparişlerin üzerinden yüksek komisyonlar almaz. Her 1000
                sipariş için yalnızca ₺1000 komisyon talep eder. Kazancın
                tamamen senin!
              </p>
            </div>
          </div>

          <div className="p-4 pb-8 rounded-lg border-[1px] border-black/5 flex flex-col items-center gap-y-2">
            <div className="mt-4 bg-primary p-2 rounded-full w-fit">
              <FaHeadset className="text-[32px] text-white" />
            </div>

            <div>
              <h6 className="text-[20px] Gilroy-SemiBold">
                Müşteri Desteğimiz
              </h6>
            </div>

            <div>
              <p className="text-[14px] Gilroy-Medium text-black/70">
                Her zaman yanında! Yemek.Club teknik ekibi, karşılaştığın her
                sorunda 7/24 yardıma hazır. Ayrıca, panel tanıtımı için
                dilediğin zaman teknik ekibimizden randevu alabilirsin. Her
                adımda seni destekliyoruz! Senin Uygulaman!
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-12 lg:mt-24 bg-primary py-12">
        <div>
          <h6 className="text-white text-center Gilroy-Bold text-[42px]">
            Yönetim Panelimiz
          </h6>
          <p className="text-center text-white Gilroy-Regular">
            Menü oluşturma, sipariş takibi ve analizlerle işletmeni kolayca
            yönet. Yorumlarınızı takip edin, anında geri dönüş yapın!
          </p>
        </div>

        <div className="mt-8 flex flex-col lg:flex-row items-center justify-center gap-x-12">
          <div className="flex flex-col gap-y-12">
            <div className="gap-y-3 flex flex-col text-white lg:items-end lg:text-right w-[300px]">
              <div>
                <FaUtensils className="text-[24px]" />
              </div>
              <div>
                <h6 className="lg:text-right Gilroy-SemiBold text-[18px]">
                  Yemek ve Menü Yönetimi
                </h6>
              </div>
              <div>
                <p className="Gilroy-Medium">
                  Menünü dilediğin gibi oluştur, yemekleri kolayca ekle, düzenle
                  veya kaldır. Kontrol tamamen sende!
                </p>
              </div>
            </div>

            <div className="gap-y-3 flex flex-col text-white lg:items-end lg:text-right w-[300px]">
              <div>
                <FaBox className="text-[24px]" />
              </div>
              <div>
                <h6 className="lg:text-right Gilroy-SemiBold text-[18px]">
                  Anlık Sipariş Takibi
                </h6>
              </div>
              <div>
                <p className="Gilroy-Medium">
                  Müşterilerinden gelen siparişlerin tümünü anlık olarak panel
                  üzerinden takip edebilirsin. Siparişin her aşamasını görmek ve
                  gerektiğinde müdahale etmek çok kolay.
                </p>
              </div>
            </div>
          </div>
          <div className="hidden lg:flex lg:flex-col">
            <img src="/assets/img/buisness_mockup.webp" className="w-[600px]" />
          </div>
          <div className="flex flex-col gap-y-12 mt-12 lg:mt-0">
            <div className="gap-y-3 flex flex-col text-white items-start text-left w-[300px]">
              <div>
                <FaChartLine className="text-[24px]" />
              </div>
              <div>
                <h6 className="text-right Gilroy-SemiBold text-[18px]">
                  Detaylı Analiz
                </h6>
              </div>
              <div>
                <p className="Gilroy-Medium">
                  İşletmendeki ürünlerin sipariş adetlerini, online veya nakit
                  ödeme türlerini ve daha fazlasını gerçek zamanlı analiz
                  edebilirsin. Her türlü veri, işletmenle ilgili önemli kararlar
                  almanı sağlar.
                </p>
              </div>
            </div>

            <div className="gap-y-3 flex flex-col text-white items-start text-left w-[300px]">
              <div>
                <FaComment className="text-[24px]" />
              </div>
              <div>
                <h6 className="text-right Gilroy-SemiBold text-[18px]">
                  Yorumlar
                </h6>
              </div>
              <div>
                <p className="Gilroy-Medium">
                  İşletmene gelen yorumları ve puanları kolayca
                  görüntüleyebilirsin. Ayrıca, müşterilerinin yorumlarına
                  hızlıca cevap vererek etkileşimini artırabilirsin.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container mx-auto mt-12 lg:mt-24">
        <div className="text-center">
          <h2 className="Gilroy-SemiBold text-[48px] lg:text-[42px]">
            Nasıl Üye İşyeri Olabilirim?
          </h2>
          <p className="hidden lg:flex lg:flex-col w-9/12 mx-auto Gilroy-Medium text-black/60">
            Yemek.Club’a üye işyeri olmak çok kolay! Başvuru formunu doldur,
            ekibimiz seninle iletişime geçsin. Gereken adımları birlikte
            tamamlayalım ve hemen başlayalım!
          </p>
        </div>

        <div className="mt-8 flex flex-col lg:flex-row justify-around gap-x-8 gap-y-8 px-2 lg:px-0">
          <div className=" flex items-center flex-col lg:w-4/12 text-center">
            <div className="bg-primary rounded-full w-[50px] h-[50px] flex flex-col justify-center items-center">
              <p className="text-white text-[28px]">1</p>
            </div>

            <div>
              <h6 className="text-black Gilroy-Bold text-[21px] mt-2">
                Ön Başvuru Formunu Tamamla
              </h6>

              <p className="Gilroy-Regular text-black/80 mt-2">
                Web sitemiz üzerinden{" "}
                <span>
                  <a
                    href="/uye-isyeri-onbasvuru-formu"
                    className="text-primary Gilroy-SemiBold"
                  >
                    Ön Başvuru Formu
                  </a>
                </span>
                'nu doldurarak, üye işyeri olma talebinizi hızlıca bize
                iletebilirsiniz.
              </p>
            </div>
          </div>

          <div className=" flex items-center flex-col lg:w-4/12 text-center">
            <div className="bg-primary rounded-full w-[50px] h-[50px] flex flex-col justify-center items-center">
              <p className="text-white text-[28px]">2</p>
            </div>

            <div>
              <h6 className="text-black Gilroy-Bold text-[21px] mt-2">
                Bizimle İletişime Geç
              </h6>

              <p className="Gilroy-Regular text-black/80 mt-2">
                Başvurunuz onaylandığında, satış temsilcimiz sizinle iletişime
                geçecek ve süreçle ilgili bazı bilgileri sizden talep edecektir.
              </p>
            </div>
          </div>

          <div className=" flex items-center flex-col lg:w-4/12 text-center">
            <div className="bg-primary rounded-full w-[50px] h-[50px] flex flex-col justify-center items-center">
              <p className="text-white text-[28px]">3</p>
            </div>

            <div>
              <h6 className="text-black Gilroy-Bold text-[21px] mt-2">
                Sözleşmeler
              </h6>

              <p className="Gilroy-Regular text-black/80 mt-2">
                Size göndereceğimiz sözleşmeleri inceleyip imzalayarak, faks
                veya kargo aracılığıyla bize gönderebilirsiniz. Her aşamada
                yanınızdayız ve sürecin her adımında size yardımcı olmaktan
                mutluluk duyarız!
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-24 w-full bg-primary py-16 text-center">
        <h2 className="Gilroy-Bold text-white text-[48px] lg:text-[62px] ">
          Aklınıza birşey mi takıldı ?
        </h2>
        <p className="Gilroy-Regular text-white/80 text-[18px] lg:text-[24px] mt-2 ">
          Aklınıza takılan sorular için iletişim formumuzu doldurarak destek
          ekibimiz tarafından bilgi alabilirsiniz.
        </p>
        <a href="/iletisim">
          <button className="Gilroy-Bold text-[18px] text-white border-white border-[2px]  py-3 px-12 rounded-full mt-7">
            Hemen Bilgi Al
          </button>
        </a>
      </div>

      <footer>
        <Footer />
      </footer>
    </div>
  );
}

export default Buisness;
