import React, { useEffect, useState } from "react";
import { FaBars } from "react-icons/fa6";
function Header() {
  const [urlPath, setUrlPath] = useState("");
  const [openSubMenu, setOpenSubMenu] = useState(false);
  const [openMobileMenu, setOpenMobileMenu] = useState(false);
  useEffect(() => {
    if (window.location.href.split("/")[3]) {
      setUrlPath(window.location.href.split("/")[3]);
    } else {
      setUrlPath("/");
    }
  });
  return (
    <div className="container mx-auto mt-2">
      <div className="flex justify-between items-center px-2">
        <div className="flex gap-x-3 items-center">
          <a href="/" className="bg-primary w-10 h-10 p-1 rounded-lg">
            <img alt="logo_white.webp" src="/assets/img/logo_white.webp" />
          </a>
          <div>
            <a href="/" className="text-primary text-[34px] Gilroy-ExtraBold">
              Yemek.Club
            </a>
          </div>
        </div>

        <div className="hidden md:flex gap-x-12 items-center">
          <div>
            <a
              href="/"
              className={`${
                urlPath == "/"
                  ? "Gilroy-Bold text-primary"
                  : "Gilroy-SemiBold text-black/90"
              }  text-[17px] hover:text-primary`}
            >
              Ana Sayfa
            </a>
          </div>
          <div>
            <a
              href="/hakkimizda"
              className={`${
                urlPath?.includes("hakkimizda")
                  ? "Gilroy-Bold text-primary"
                  : "Gilroy-SemiBold text-black/90"
              }  text-[17px] hover:text-primary`}
            >
              Hakkımızda
            </a>
          </div>
          <div>
            <a
              href="/isletmeler"
              className={`${
                urlPath?.includes("isletmeler")
                  ? "Gilroy-Bold text-primary"
                  : "Gilroy-SemiBold text-black/90"
              }  text-[17px] hover:text-primary`}
            >
              İşletmeler İçin
            </a>
          </div>
          <div>
            <a
              href="/yazilarimiz"
              className={`${
                urlPath?.includes("yazilarimiz")
                  ? "Gilroy-Bold text-primary"
                  : "Gilroy-SemiBold text-black/90"
              }  text-[17px] hover:text-primary`}
            >
              Blog
            </a>
          </div>
          <a href="/iletisim">
            <button className="bg-primary py-2 px-8 rounded-lg">
              <p className="Gilroy-Bold text-white text-[17px]">İletişim</p>
            </button>
          </a>
        </div>

        <button
          onClick={() => {
            setOpenMobileMenu(!openMobileMenu);
          }}
          className="flex flex-col lg:hidden"
        >
          <FaBars className="text-black/80 text-[22px]" />
        </button>
        {openMobileMenu && (
          <div className="w-full bg-white absolute top-16 p-4 left-0 border-b-[1px] z-10">
            <ul className="gap-y-4 flex flex-col">
              <li>
                <a
                  href="/"
                  className={`${
                    urlPath == "/"
                      ? "Gilroy-Bold text-primary"
                      : "Gilroy-SemiBold text-black/90"
                  }  text-[17px] hover:text-primary`}
                >
                  Ana Sayfa
                </a>
              </li>
              <li>
                <a
                  href="/hakkimizda"
                  className={`${
                    urlPath?.includes("hakkimizda")
                      ? "Gilroy-Bold text-primary"
                      : "Gilroy-SemiBold text-black/90"
                  }  text-[17px] hover:text-primary`}
                >
                  Hakkımızda
                </a>
              </li>
              <li>
                <a
                  href="/isletmeler"
                  className={`${
                    urlPath?.includes("isletmeler")
                      ? "Gilroy-Bold text-primary"
                      : "Gilroy-SemiBold text-black/90"
                  }  text-[17px] hover:text-primary`}
                >
                  İşletmeler İçin
                </a>
              </li>
              <li>
                <a
                  href="/yazilarimiz"
                  className={`${
                    urlPath?.includes("yazilarimiz")
                      ? "Gilroy-Bold text-primary"
                      : "Gilroy-SemiBold text-black/90"
                  }  text-[17px] hover:text-primary`}
                >
                  Blog
                </a>
              </li>
              <li>
                <a
                  href="/iletisim"
                  className={`${
                    urlPath?.includes("iletisim") &&
                    "text-primary Gilroy-SemiBold"
                  }  hover:text-primary Gilroy-SemiBold`}
                >
                  İletişim
                </a>
              </li>
            </ul>
          </div>
        )}
      </div>
    </div>
  );
}

export default Header;
